import { useMemo } from "react";
import { useNotify } from "services/notification";
import { useOrder } from "store";
import {
  MoveTrackerStep,
  MoveTrackerStepStatus,
  useMoveTrackerStepsQuery,
} from "__generated__/types";

export function useMoveTrackerSteps() {
  const { id: orderId } = useOrder();
  const notify = useNotify();
  const { data, loading, error } = useMoveTrackerStepsQuery({
    variables: { orderId: orderId || "" },
    skip: !orderId,
    onError: (error) => {
      console.error(error);
      notify(
        "There was a problem getting your move steps. Please try again later.",
        {
          variant: "error",
        }
      );
    },
  });

  const steps = data?.moveTrackerSteps?.nodes || [];

  const moveCompletedAt = steps.length > 0 ? getCompletedAt(steps) : null;

  return {
    loading,
    error,
    steps,
    moveCompletedAt,
  };
}

function getCompletedAt(steps: Array<MoveTrackerStep | null>): string | null {
  const lastStep = steps[steps.length - 1];
  return lastStep?.stepStatus === MoveTrackerStepStatus.Completed
    ? lastStep?.service?.actualEndDatetime
    : null;
}
