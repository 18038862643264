import MuiAccordian, { AccordionProps } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Button } from "components/common/ui/Button";
import formJson from "assets/entitlements/forms.json";
import { fetchText, isFeatureEnabled } from "tools";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Typography, Paper, Skeleton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { Checkbox, FormControlLabel } from "@mui/material";
import ReactMarkdown from "react-markdown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled from "@emotion/styled";
import { theme } from "styles";
import { useOrder } from "store";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import DOMpurify from "dompurify";
import { Order } from "models";
import jsonLogic from "json-logic-js";

const getAcknowledgementRequired = (
  rules: jsonLogic.RulesLogic<any>,
  order: Order
) => {
  return jsonLogic.apply(rules, {
    order,
    entitlement: order.entitlement,
  });
};

const ButtonWrapper = styled.div`
  margin-top: 32px;
  max-width: 300px;
`;

const Accordion = styled(MuiAccordian)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0;

  &:before {
    display: none;
  }

  &.MuiPaper-root,
  .MuiPaper-root {
    color: inherit;
    padding: 0 4px;
  }

  .MuiPaper-root:before {
    display: none;
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionDetails-root {
    padding: 0;
    padding-bottom: 32px;
  }

  .MuiAccordionSummary-content {
    margin: 24px 0;
  }

  code {
    white-space: pre-wrap;
  }

  a {
    word-wrap: break-word;
  }
`;

const markdownComponents = {
  h3: ({ children }: PropsWithChildren) => (
    <Typography
      variant="mHeading"
      component="h3"
      sx={{
        marginTop: "24px",
        marginBottom: "8px",
        "&:first-of-type": {
          marginTop: "0px",
        },
      }}
    >
      {children}
    </Typography>
  ),
  p: ({ children }: PropsWithChildren) => (
    <Typography variant="sBody" component="p" mb="8px">
      {children}
    </Typography>
  ),
  li: ({ children }: PropsWithChildren) => (
    <Typography variant="sBody" component="li" mb="4px">
      {children}
    </Typography>
  ),
  ul: ({ children }: PropsWithChildren) => (
    <Box component="ul" mt="0px">
      {children}
    </Box>
  ),
  ol: ({ children }: PropsWithChildren) => (
    <Box component="ol" mt="0px">
      {children}
    </Box>
  ),
  a: (props: PropsWithChildren) => <a {...props} target="_blank" />,
  table: ({ children }: PropsWithChildren) => (
    <Typography variant="sBody" component="table" mb={1}>
      {children}
    </Typography>
  ),
  th: ({ children }: PropsWithChildren) => (
    <Typography variant="sBody" sx={{ fontWeight: 400 }} component="th">
      {children}
    </Typography>
  ),
  u: ({ children }: PropsWithChildren) => <u>{children}</u>,
};

const MarkdownContent: React.FC<{ markdown: string }> = ({ markdown }) => {
  return useMemo(
    () => (
      <ReactMarkdown
        components={markdownComponents}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
      >
        {DOMpurify.sanitize(markdown)}
      </ReactMarkdown>
    ),
    []
  );
};

type FormInfo = {
  title: string;
  name: string;
  disclaimerVerbage?: string;
  acknowledgementRequired?: boolean;
  branch: Array<string>;
  content: React.ReactNode;
  hasAgreed: boolean;
};

interface Props {
  onComplete?(isComplete: boolean): void;
  readonly: boolean;
  /**
   * Set to true to show all agreements, otherwise
   * we only show the acknoqledgement required ones
   */
  showAll?: boolean;
}

export const Agreements: React.FC<Props> = ({
  onComplete,
  readonly = false,
  showAll = false,
}) => {
  const order = useOrder();
  const hasAlreadyAgreed = order.hasCompletedEntitlementsCounseling;
  const [forms, setForms] = useState<Array<FormInfo> | null>(null);
  const [expanded, setExpanded] = useState<null | string>(null);

  useEffect(() => {
    const getDocuments = async () => {
      const directory = "/assets/forms";
      const docFileRequests = formJson.map(async (form) => {
        try {
          return await fetchText(`${directory}/${form.name}.md`);
        } catch (err) {
          return "ERROR: MISSING DOCUMENT";
        }
      });

      const docs = await Promise.all(docFileRequests);

      const formattedForms = formJson
        .map((item, index) => {
          const acknowledgementRequired = getAcknowledgementRequired(
            item.acknowledgementRequired,
            order
          );
          return {
            ...item,
            content: <MarkdownContent markdown={docs[index]} />,
            hasAgreed: hasAlreadyAgreed || false,
            acknowledgementRequired,
          };
        })
        .filter((form) => {
          return form.acknowledgementRequired || showAll;
        });
      setForms(formattedForms);
      checkComplete(formattedForms);
      setExpanded(formattedForms[0]?.name);
    };

    getDocuments();
  }, []);

  const goToNext = () => {
    if (!forms) {
      return;
    }
    const currIndex = forms.findIndex((f) => f.name === expanded);

    if (currIndex >= 0) {
      const next = forms[currIndex + 1];
      if (next) setExpanded(next.name);
    }
  };

  /**
   * Checks if all the required check boxes
   * are complete, and fires the `onComplete`
   * prop if so.
   */
  const checkComplete = (forms: FormInfo[]) => {
    if (
      forms.length > 0 &&
      forms.every((form) => {
        if (form.acknowledgementRequired) {
          return form.hasAgreed;
        }
        return true;
      })
    ) {
      onComplete && onComplete(true);
    } else {
      onComplete && onComplete(false);
    }
  };

  const toggleAgree = (formName: string) => {
    if (!forms) {
      return;
    }
    const newForms = forms.map((form) => {
      if (form.name === formName) {
        form.hasAgreed = !form.hasAgreed;
      }
      return form;
    });
    checkComplete(newForms);
    setForms(newForms);
  };

  if (forms === null) {
    return (
      <div>
        <Skeleton animation="wave" height="60px" />
        <Skeleton animation="wave" height="60px" />
        <Skeleton animation="wave" height="60px" />
      </div>
    );
  }

  return (
    <div>
      <Box maxWidth="800px">
        {forms.map((form, index) => {
          const isCurrent = expanded === form.name;
          const isLast = index === forms.length - 1;
          const elId = `form_${form.name}`;
          return (
            <Accordion
              id={elId}
              data-testid={elId}
              key={form.name}
              expanded={isCurrent}
              onChange={(e) => {
                setExpanded(isCurrent ? null : form.name);
              }}
              disableGutters
              elevation={0}
              square
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                expandIcon={<ExpandMoreIcon />}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  mr="16px"
                >
                  <Typography variant="mHeading">{form.title}</Typography>
                  {form.acknowledgementRequired && !form.hasAgreed ? (
                    <Typography
                      variant="xxsBody"
                      color={theme.palette.colors.error}
                    >
                      Requires acknowledgement
                    </Typography>
                  ) : null}
                  {form.hasAgreed && form.acknowledgementRequired ? (
                    <CheckCircleIcon color="success" />
                  ) : null}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Paper>
                  <Box maxHeight="450px" overflow="scroll" padding="32px">
                    <div>{form.content}</div>
                    {form.acknowledgementRequired ? (
                      <Box mt="24px" p="24px" bgcolor="rgba(201, 136, 35, 0.1)">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={form.hasAgreed}
                              onChange={(e) => {
                                if (readonly) {
                                  return;
                                }
                                toggleAgree(form.name);
                                if (e.target.checked) {
                                  goToNext();
                                }
                              }}
                              name={form.name}
                            />
                          }
                          label={
                            <Typography variant="mBody">
                              {form.disclaimerVerbage}
                            </Typography>
                          }
                          aria-label={form.disclaimerVerbage}
                        />
                      </Box>
                    ) : !isLast &&
                      isFeatureEnabled("FF_ENTITLEMENTS_GUI_110923") ? (
                      <ButtonWrapper>
                        <Button onClick={goToNext} size="medium">
                          Read Next Section
                        </Button>
                      </ButtonWrapper>
                    ) : null}
                  </Box>
                </Paper>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </div>
  );
};
