"use client";
import { useRouter } from "next/navigation";
import {
  useIntl,
  translationKeys,
  FormattedMessage,
} from "services/translation";
import { TwoColumn } from "components/common/ui/TwoColumn";
import { BackNav } from "components/common/ui/BackNav";
import { Badge } from "components/common/ui/Badge";
import { Card } from "components/common/ui/Card";
import { OriginAndDestinationCard } from "components/common/AddressTable/OriginAndDestinationCard";
import { useOrders } from "hooks";
import { Skeleton, Alert, Box } from "@mui/material";
import { Order } from "models";
import { DepotStatus } from "types";
import { useMe } from "hooks/useMe";
import { H1 } from "components/common/Typography/typography";
import { PageWrapper } from "components/common/PageWrapper";
import { useOrderLoader } from "services/orders/OrderLoaderContext";

type OrderCardProps = {
  selectOrder: (id: string) => void;
  order: Order;
  selected: boolean;
};

const getMoveStatusLabelKey = (status: DepotStatus): translationKeys => {
  switch (status) {
    case "CANCELLED":
      return "moveStatus.cancelled";
    case "COMPLETED":
      return "moveStatus.complete";
    case "COUNSELING_COMPLETED":
    case "SURVEY_SCHEDULED":
      return "moveStatus.survey";
    case "SURVEY_IN_PROGRESS":
      return "moveStatus.surveyInProgress";
    case "SURVEY_COMPLETED":
      return "moveStatus.surveyCompleted";
    case "PLANNED":
      return "moveStatus.surveyCompleted";
    case "IN_TRANSIT":
      return "moveStatus.inTransit";
    case "IN_STORAGE_IN_TRANSIT":
    case "IN_NON_TEMPORARY_STORAGE":
    case "IN_STORAGE_PARTIAL":
      return "moveStatus.inStorage";
    case "PACKING_STARTED":
    case "PACKING_COMPLETED":
    case "PACK_CREW_DISPATCHED":
      return "moveStatus.pack";
    case "LOAD_CREW_DISPATCHED":
    case "LOADING_STARTED":
      return "moveStatus.load";
    case "DELIVERY_COMPLETED":
    case "DELIVERY_CREW_DISPATCHED":
    case "DELIVERY_STARTED":
      return "moveStatus.deliver";
    case "UNKNOWN":
      return "moveStatus.unknown";
    case "RECEIVED":
    case "APPROVED":
      return "moveStatus.plan";
    default: {
      const _exhaustiveCheck: never = status;
      return _exhaustiveCheck;
    }
  }
};

const OrderCard = ({ selectOrder, order, selected }: OrderCardProps) => {
  return (
    <Card
      active={selected}
      title={`Order #${order.number}`}
      extraTitleContent={<MoveStatusBadge status={order.status} />}
    >
      <OriginAndDestinationCard
        origin={order.origin}
        destination={order.destination}
      />
      <Box my={2}>
        <a onClick={() => selectOrder(order.id!)}>View Details</a>
      </Box>
    </Card>
  );
};

const greenMoveStatuses = new Set<string>(["DELIVERY_COMPLETED", "COMPLETED"]);
const redStatuses = new Set<string>(["CANCELLED"]);
const MoveStatusBadge = ({ status }: { status: DepotStatus }) => {
  const { formatMessage } = useIntl();
  const color = greenMoveStatuses.has(status)
    ? "green"
    : redStatuses.has(status)
    ? "red"
    : "blue";

  const labelKey = getMoveStatusLabelKey(status);
  return (
    <>
      <Badge key={status} color={color}>
        {formatMessage({ id: labelKey })}
      </Badge>
    </>
  );
};

export function MyOrdersPage() {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { orders, loading, errors } = useOrders();
  const { savedOrderId, changeOrder } = useOrderLoader();
  const me = useMe();
  const handleChange = (id: string) => {
    changeOrder(id);
    router.push("/");
  };

  return (
    <PageWrapper>
      <TwoColumn>
        <div>
          <BackNav ariaLabel={"ariaLabels.returnHome"} />
          <H1 mb="32px">My Orders</H1>
          {/*TODO: Display more helpful errors */}
          {errors && (
            <Box my={4}>
              <Alert severity="error">
                <FormattedMessage id="myOrders.error" />
              </Alert>
            </Box>
          )}
          {!loading && !errors && orders.length === 0 ? (
            <div>
              {formatMessage(
                {
                  id: "myOrders.noOrders",
                },
                { email: me?.email || "" }
              )}
            </div>
          ) : null}
          {loading
            ? [1, 2, 3].map((i) => (
                <Card key={i} title={<Skeleton height={20} width="1" />}>
                  <Skeleton variant="rectangular" height={150} width="1" />
                </Card>
              ))
            : orders.map((order) => (
                <OrderCard
                  key={order.id}
                  order={order}
                  selectOrder={handleChange}
                  selected={order.id === savedOrderId}
                />
              ))}
        </div>
      </TwoColumn>
    </PageWrapper>
  );
}
