import { FC, ReactNode } from "react";
import classNames from "classnames";
import { CrewTracker } from "models";
import scss from "./TrackYourMoveTimeline.module.scss";
import { formatDate } from "@movehq/datetime";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import {
  BaseService,
  MoveTrackerKey,
  MoveTrackerStep,
  MoveTrackerStepStatus,
  ServiceStatus,
} from "__generated__/types";
import { FormattedMessage, translationKeys } from "services";

const TimelineElementWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  &:last-child {
    div:before {
      display: none;
    }
  }
`;

const IconWrapper = styled.div`
  z-index: 110;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  margin-right: 8px;
  position: relative;
  img {
    z-index: 1;
    display: block;
    position: relative;
  }
  &:before {
    content: "";
    position: absolute;
    top: 18px;
    left: 12.5px;
    z-index: -100;
    height: 45px;
    background: transparent;
    border-left: 2px dashed #e5e5e5;
  }
`;

const variantIcons = {
  COMPLETED: "/images/track/completed.svg",
  CURRENT: "/images/track/inProgress.svg",
  UNSTARTED: "/images/track/pending.svg",
};

type ServiceMap = {
  [key in MoveTrackerKey]?: {
    subtext?: {
      [key in ServiceStatus]?: string;
    };
    label: translationKeys;
  };
};

const TimelineElement = ({
  stepStatus,
  children,
  testId,
}: {
  stepStatus: MoveTrackerStepStatus;
  children: ReactNode;
  testId: string;
}) => {
  const iconImage = variantIcons[stepStatus];
  return (
    <TimelineElementWrapper data-testid={testId} data-testvariant={stepStatus}>
      <IconWrapper>
        <img src={iconImage} />
      </IconWrapper>
      <Box ml="6px" position="relative" flexGrow={1}>
        {children}
      </Box>
    </TimelineElementWrapper>
  );
};

const ProgressIndicator = (props: { children: ReactNode }) => (
  <Typography
    variant="xxsBody"
    color="#666"
    display="block"
    lineHeight="13px"
    {...props}
  ></Typography>
);

export const moveTrackerServiceMap: ServiceMap = {
  PACK: {
    subtext: {
      CREW_DISPATCHED: "Crew En Route",
      IN_PROGRESS: "In Progress",
    },
    label: "moveTracker.step.heading.pack",
  },
  LOAD: {
    subtext: {
      CREW_DISPATCHED: "Crew En Route",
      IN_PROGRESS: "In Progress",
    },
    label: "moveTracker.step.heading.load",
  },
  TRANSPORTATION: {
    subtext: {
      IN_PROGRESS: "In Progress",
    },
    label: "moveTracker.step.heading.transportation",
  },
  DELIVERY: {
    subtext: {
      CREW_DISPATCHED: "Crew En Route",
      IN_PROGRESS: "Unloading",
      PERFORMED: "Completed",
    },
    label: "moveTracker.step.heading.delivery",
  },
  ARRIVED_AT_STORAGE_ORIGIN: {
    label: "moveTracker.step.heading.sitFirstDayAtOrigin",
  },
  ARRIVED_AT_STORAGE_DESTINATION: {
    label: "moveTracker.step.heading.sitFirstDayAtDestination",
  },
  ARRIVED_AT_STORAGE_DESTINATION_PARTIAL: {
    label: "moveTracker.step.heading.sitFirstDayAtDestination",
  },
  FINAL_DELIVERY: {
    label: "moveTracker.step.heading.finalDelivery",
    subtext: {
      CREW_DISPATCHED: "Crew En Route",
      IN_PROGRESS: "Unloading",
      PERFORMED: "Completed",
    },
  },
  PARTIAL_DELIVERY: {
    label: "moveTracker.step.heading.partialDelivery",
    subtext: {
      CREW_DISPATCHED: "Crew En Route",
      IN_PROGRESS: "Unloading",
      PERFORMED: "Completed",
    },
  },
  SIT_DELIVERY: {
    label: "moveTracker.step.heading.sitDelivery",
    subtext: {
      CREW_DISPATCHED: "Crew En Route",
      IN_PROGRESS: "Unloading",
      PERFORMED: "Completed",
    },
  },
};

const TimelineContent: FC<{
  stepKey: MoveTrackerKey;
  service?: Partial<BaseService> | null;
  stepStatus: MoveTrackerStepStatus;
  crewTracker?: CrewTracker;
}> = ({ service, stepKey, stepStatus }) => {
  const subtext =
    stepKey && service?.status
      ? moveTrackerServiceMap[stepKey]?.["subtext"]?.[service.status]
      : "";
  try {
    const scheduledDate = service?.plannedStartDatetime;

    return (
      <Box position="absolute" top="24px">
        {stepStatus === MoveTrackerStepStatus.Completed ? (
          <ProgressIndicator>Completed</ProgressIndicator>
        ) : stepStatus === MoveTrackerStepStatus.Current ? (
          <ProgressIndicator>{subtext || "In Progress"}</ProgressIndicator>
        ) : (
          <div aria-label="Stage not yet reached">
            {scheduledDate && (
              <ProgressIndicator data-testid={`planned-${service.type}-date`}>
                Scheduled for {formatDate(scheduledDate, "twoDigitDayAndMonth")}
              </ProgressIndicator>
            )}
          </div>
        )}
      </Box>
    );
  } catch (e) {
    console.error(e);
    return <div />;
  }
};

export function TrackYourMoveTimeline({
  steps,
}: {
  steps: Array<MoveTrackerStep | null>;
}) {
  return (
    <div
      className={classNames(scss.timelineContainer)}
      data-testid="timeline"
      id="timeline"
    >
      <div className={classNames(scss.timeline)}>
        {steps?.map((step, index) => {
          if (step) {
            return (
              <TimelineElement
                testId={`trackYourMove.${step.key.toLowerCase()}`}
                stepStatus={step.stepStatus}
                key={index}
              >
                <Typography variant="sHeading">
                  {FormattedMessage({
                    id: moveTrackerServiceMap[step.key]?.["label"],
                  })}
                </Typography>
                <TimelineContent
                  stepKey={step.key}
                  service={step.service}
                  stepStatus={step.stepStatus}
                />
              </TimelineElement>
            );
          }
        })}
      </div>
    </div>
  );
}
