"use client";
import { FormattedMessage, useIntl } from "services";
import { BackNav, Panel } from "components";
import { useEffect, useState } from "react";
import { CounselingLayout } from "../components/CounselingLayout";
import { CounselingButton } from "../components/CounselingButton";
import { counselingStatusToPathMap, SurveyScheduleType } from "types";
import { useCounselingSubmit } from "hooks";
import { RequestOnsiteSurvey } from "models/RequestOnsiteSurvey";
import { useOrder } from "store";
import {
  useCreateSurveyAppointmentMutation,
  usePendingSurveyServiceQuery,
  useSurveyAvailabilitiesQuery,
} from "__generated__/types";

export function SurveyType() {
  const { formatMessage } = useIntl();
  const order = useOrder();
  const [selectedScheduleType, setSelectedScheduleType] =
    useState<SurveyScheduleType | null>("VIRTUAL");

  const { data: surveyServiceData, loading: surveyServiceLoading } =
    usePendingSurveyServiceQuery({
      variables: { orderId: order.id! },
      skip: !order,
    });

  const {
    data: surveyAvailabilitiesData,
    loading: surveyAvailabilitiesLoading,
  } = useSurveyAvailabilitiesQuery({
    variables: {
      orderId: order.id!,
      startDate: surveyServiceData?.pendingSurveyService?.minSurveyDate,
      endDate: surveyServiceData?.pendingSurveyService?.maxSurveyDate,
      surveyType: "VIRTUAL",
    },
    skip: !order || !surveyServiceData,
  });

  const isVirtualSurveyAvailable = !!(
    surveyAvailabilitiesData?.surveyAvailabilities?.nodes &&
    surveyAvailabilitiesData?.surveyAvailabilities?.nodes.length > 0
  );

  const [createSurveyAppointment] = useCreateSurveyAppointmentMutation();

  const { handleNext, loading } = useCounselingSubmit({
    nextStatus:
      selectedScheduleType === "VIRTUAL"
        ? isVirtualSurveyAvailable
          ? "SURVEY_SCHEDULE_TIME"
          : "COMPLETE"
        : "COMPLETE",
    saveCallback: async () => {
      if (selectedScheduleType === "ONSITE") {
        const req = new RequestOnsiteSurvey({ order_id: order.id });
        try {
          await req.save();
          return true;
        } catch (err) {
          return false;
        }
      } else if (
        selectedScheduleType === "VIRTUAL" &&
        !isVirtualSurveyAvailable
      ) {
        const { data } = await createSurveyAppointment({
          variables: {
            input: {
              orderId: order.id || "",
              surveyAvailabilityId: null,
            },
          },
        });
        return !!data;
      }
      return true;
    },
  });

  return (
    <CounselingLayout ariaLabel={"ariaLabels.counseling.survey.type"}>
      <BackNav href={counselingStatusToPathMap.MOVE_DATES} />
      <CounselingLayout.Heading>
        <FormattedMessage id="counseling.surveyType.title" />
      </CounselingLayout.Heading>
      <CounselingLayout.InfoText>
        <FormattedMessage id="counseling.surveyType.instructions" />
      </CounselingLayout.InfoText>
      <Panel
        icon="boxes_and_lamp"
        title={<FormattedMessage id="counseling.surveyType.virtual.title" />}
        ariaLabel={formatMessage({
          id: "ariaLabels.counseling.survey.virtual",
        })}
        onClick={() => {
          setSelectedScheduleType("VIRTUAL");
        }}
        completed={selectedScheduleType === "VIRTUAL"}
      >
        <FormattedMessage id="counseling.surveyType.virtual.description" />
      </Panel>
      <Panel
        icon="couch"
        title={<FormattedMessage id="counseling.surveyType.onsite.title" />}
        ariaLabel={formatMessage({
          id: "ariaLabels.counseling.survey.onsite",
        })}
        onClick={() => {
          setSelectedScheduleType("ONSITE");
        }}
        completed={selectedScheduleType === "ONSITE"}
      >
        <FormattedMessage id="counseling.surveyType.onsite.description" />
      </Panel>
      <CounselingButton
        onClick={handleNext}
        isSubmitting={loading}
        disabled={
          selectedScheduleType === null ||
          surveyServiceLoading ||
          surveyAvailabilitiesLoading
        }
      />
    </CounselingLayout>
  );
}
