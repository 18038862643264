import { FormattedMessage, useIntl, translationKeys } from "services";
import { useOrder } from "store";
import { formatTime } from "@movehq/datetime";
import { getOrderStatusValue } from "tools";
import { ReviewYourMovePanel } from "./ReviewYourMovePanel";
import { DepotStatus } from "types";
import { H1, P } from "components/common/Typography/typography";
import { TrackYourMovePanel } from "./TrackYourMovePanel/TrackYourMovePanel";
import { SurveyPanel } from "./SurveyCallScheduledPanel/SurveyPanel";
import { useMoveTrackerSteps } from "hooks/useMoveTrackerSteps";

const getTitleId = (orderStatus: DepotStatus): translationKeys => {
  switch (orderStatus) {
    case "RECEIVED":
    case "APPROVED":
    case "COUNSELING_COMPLETED":
    case "SURVEY_SCHEDULED":
    case "SURVEY_IN_PROGRESS":
    case "SURVEY_COMPLETED":
    case "PLANNED": {
      return "landingPage.manageYourMove";
    }

    case "PACK_CREW_DISPATCHED":
    case "PACKING_STARTED":
    case "PACKING_COMPLETED":
    case "LOAD_CREW_DISPATCHED":
    case "LOADING_STARTED":
    case "IN_TRANSIT":
    case "IN_STORAGE_IN_TRANSIT":
    case "IN_NON_TEMPORARY_STORAGE":
    case "DELIVERY_CREW_DISPATCHED":
    case "IN_STORAGE_PARTIAL":
    case "DELIVERY_STARTED":
    case "DELIVERY_COMPLETED":
    case "COMPLETED": {
      return "landingPage.moveInProgress";
    }
    case "CANCELLED":
    case "UNKNOWN": {
      return "landingPage.manageYourMove";
    }

    default: {
      // forces us to account for every case
      const _exhaustiveCheck: never = orderStatus;
      return "landingPage.manageYourMove";
    }
  }
};

const getSubtitleId = (status: DepotStatus): translationKeys | null => {
  const currentStatusValue = getOrderStatusValue(status);
  const packStatusValue = getOrderStatusValue("PACK_CREW_DISPATCHED"); // 40
  const deliveredStatusValue = getOrderStatusValue("DELIVERY_COMPLETED"); // 90 (previously 95)

  const isNotPacked = currentStatusValue < packStatusValue;
  const isNotDelivered = currentStatusValue < deliveredStatusValue;
  const isDelivered = currentStatusValue >= deliveredStatusValue;

  switch (true) {
    case isNotPacked:
      return "landingPage.completeTasks";

    case isNotDelivered:
      return null;

    default:
      return null;
  }
};

type SubtitleProps = {
  subtitleId: translationKeys | null;
  dateTimeCompleted?: string | null;
};
const Subtitle = ({ subtitleId, dateTimeCompleted }: SubtitleProps) => {
  const { formatMessage } = useIntl();

  return subtitleId ? (
    <P
      aria-label={formatMessage({
        id: "ariaLabels.landingPageSubTitle",
      })}
    >
      <FormattedMessage id={subtitleId} values={{ dateTimeCompleted }} />
    </P>
  ) : null;
};

export function ManageSection() {
  const { formatMessage } = useIntl();
  const { status } = useOrder();
  const { loading, error, steps, moveCompletedAt } = useMoveTrackerSteps();
  const subtitleId = moveCompletedAt
    ? "landingPage.timeComplete"
    : getSubtitleId(status);

  const titleId = moveCompletedAt
    ? "landingPage.moveComplete"
    : getTitleId(status);
  const dateTimeCompleted =
    moveCompletedAt && formatTime(moveCompletedAt, "dateAtTime");

  return (
    <div aria-label={formatMessage({ id: "ariaLabels.manageSections" })}>
      <div className="page-title">
        <H1
          aria-label={formatMessage({ id: "ariaLabels.landingPageTitle" })}
          mb="8px"
        >
          <FormattedMessage id={titleId} />
        </H1>
        <Subtitle
          subtitleId={subtitleId}
          dateTimeCompleted={dateTimeCompleted}
        />
      </div>
      <SurveyPanel />
      <TrackYourMovePanel moveTracker={{ loading, error: !!error, steps }} />
      <ReviewYourMovePanel />
    </div>
  );
}
