"use client";
import counselingWelcomeSVG from "assets/counseling/welcome.svg";
import { Panel, TwoColumn, UserDetailsCard } from "components";
import { useOrder } from "store";
import { FormattedMessage, useIntl } from "services";
import scss from "./WelcomePage.module.scss";
import styled from "@emotion/styled";
import { ContinueButton, InfoText } from "./components";
import { H1 } from "components/common/Typography/typography";
import { Typography } from "@mui/material";
import { PageWrapper } from "components/common/PageWrapper";
import { CounselingButton } from "./components/CounselingButton";
import { useCounselingSubmit } from "hooks";

const UserDetails = styled.div({
  width: "75%",
  minWidth: "30rem",
});

export function WelcomePage() {
  const { formatMessage } = useIntl();
  const order = useOrder();
  const { customer, moveType, entitlement } = order;
  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: order.hasEntitlementsCounseling
      ? "ENTITLEMENTS_PREFERENCES"
      : "MOVE_TYPE",
  });

  return (
    <PageWrapper>
      <TwoColumn>
        <div>
          <H1>
            <FormattedMessage id="counseling.welcome.preMoveWelcome" />
          </H1>
          <Typography variant="mBody" component="p" marginBottom={"32px"}>
            <FormattedMessage id="counseling.welcome.instructions" />
          </Typography>
          <UserDetails>
            <Panel
              className={scss.panel}
              ariaLabel={formatMessage({ id: "ariaLabels.customer.info" })}
            >
              <UserDetailsCard customer={customer} entitlement={entitlement} />
            </Panel>
            <CounselingButton onClick={handleNext} isSubmitting={loading} />
          </UserDetails>
        </div>
        <div>
          <img
            src={counselingWelcomeSVG.src}
            alt={formatMessage({ id: "imageAlt.accent.counseling.welcome" })}
          />
        </div>
      </TwoColumn>
    </PageWrapper>
  );
}
