import { MoveStageDate, MoveStageLabel } from "types";
import { FormattedMessage, useIntl } from "services";
import { formatDate } from "@movehq/datetime";
import { Card } from "components";
import { DateChangeDialog } from "../DateChangeDialog";
import React, { useState } from "react";
import { InfoBox } from "components";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import styled from "@emotion/styled";
import { useMoveTrackerSteps } from "hooks/useMoveTrackerSteps";
import { MoveTrackerKey, MoveTrackerStep } from "__generated__/types";

const DatesTable = styled.table`
  width: 100%;
  table-layout: fixed;

  th,
  td {
    padding-top: 8px;
    vertical-align: top;
  }

  tbody {
    th {
      padding-top: 8px;
    }
  }
`;

export type DatesCardProps = {
  dates: Array<MoveStageDate>;
  orderId: string;
};

function DateCell({
  a11yDate,
  date,
  start,
  end,
  labelPrefix,
  testId,
  hasFootnote = false,
}: {
  a11yDate: string | null;
  date: string | null;
  start?: string | null;
  end?: string | null;
  labelPrefix: string;
  testId: string;
  hasFootnote?: boolean;
}) {
  const showDateRange = start && end && start !== end;
  return (
    <td>
      <Box data-testid={testId}>
        <Typography
          component="div"
          variant="sBody"
          aria-label={`${labelPrefix} ${a11yDate}`}
        >
          {date || "—"}
        </Typography>
        {showDateRange && (
          <Typography
            color="colors.grey30"
            variant="xxsBody"
            aria-label={`${labelPrefix} range`}
            component="div"
          >
            {start} - {end}
            {hasFootnote && `*`}
          </Typography>
        )}
      </Box>
    </td>
  );
}

function DateRow({ date }: { date: MoveStageDate }) {
  const preferredDateA11y =
    date.preferredDate && formatDate(date.preferredDate, "a11yDateFormat");
  const scheduledDateA11y =
    date.scheduledDate && formatDate(date.scheduledDate, "a11yDateFormat");
  const scheduledStartDateA11y =
    date.scheduledStartDate &&
    formatDate(date.scheduledStartDate, "a11yDateFormat");
  const completedDateA11y =
    date.completedDate && formatDate(date.completedDate, "a11yDateFormat");
  const preferredDate =
    date.preferredDate && formatDate(date.preferredDate, "twoDigitDayAndMonth");
  const scheduledDate =
    date.scheduledDate && formatDate(date.scheduledDate, "twoDigitDayAndMonth");
  const scheduledStartDate =
    date.scheduledStartDate &&
    formatDate(date.scheduledStartDate, "twoDigitDayAndMonth");
  const scheduledEndDate =
    date.scheduledEndDate &&
    formatDate(date.scheduledEndDate, "twoDigitDayAndMonth");
  const completedDate =
    date.completedDate && formatDate(date.completedDate, "twoDigitDayAndMonth");
  const startDate =
    date.possibleStartDate &&
    formatDate(date.possibleStartDate, "twoDigitDayAndMonth");
  const endDate =
    date.possibleEndDate &&
    formatDate(date.possibleEndDate, "twoDigitDayAndMonth");
  const showFootnote =
    date.changeRequestKey === "delivery_date" && endDate !== null;
  return (
    <React.Fragment key={date.label}>
      <tr data-testid={`date-row-${date.label}`}>
        <th>
          <Typography variant="mHeading">{`${date.label}`}</Typography>
        </th>
        <DateCell
          a11yDate={preferredDateA11y}
          date={preferredDate}
          labelPrefix={"Requested Date"}
          start={startDate}
          end={endDate}
          testId={`DateCard-preferred-${date.label}`}
          hasFootnote={showFootnote}
        />
        <DateCell
          a11yDate={scheduledStartDateA11y || scheduledDateA11y}
          date={scheduledStartDate || scheduledDate}
          start={scheduledStartDate || scheduledDate}
          end={scheduledEndDate}
          labelPrefix={"Scheduled Date"}
          testId={`DateCard-scheduled-${date.label}`}
        />
        <DateCell
          a11yDate={completedDateA11y}
          date={completedDate}
          labelPrefix={"Completed Date"}
          testId={`DateCard-completed-${date.label}`}
        />
      </tr>
      {date.pendingChangeRequest ? (
        <tr>
          <td colSpan={4}>
            <InfoBox changeRequest={date.pendingChangeRequest}></InfoBox>
          </td>
        </tr>
      ) : null}
      {showFootnote && (
        <tr>
          <td colSpan={4}>
            <Typography color="colors.grey30" variant="xxsBody" component="div">
              *Your Required Delivery Date (RDD) is {endDate}
            </Typography>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}

export const keyToLabelMap: Partial<Record<MoveTrackerKey, MoveStageLabel>> = {
  PACK: "Pack",
  LOAD: "Load",
  DELIVERY: "Delivery",
  FINAL_DELIVERY: "Delivery",
};

const addScheduledDatesFromSteps = (
  steps: (MoveTrackerStep | null)[],
  dates: MoveStageDate[]
) => {
  return dates.map((date) => {
    const step = steps.find(
      (step) => step && keyToLabelMap[step.key] === date.label
    );

    return !step
      ? date
      : {
          ...date,
          scheduledStartDate: step.service?.plannedStartDatetime,
          scheduledEndDate: step.service?.plannedEndDatetime,
        };
  });
};

export function DatesCard(props: DatesCardProps) {
  const { dates, orderId } = props;

  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState(false);

  const { steps } = useMoveTrackerSteps();
  const datesWithScheduled = addScheduledDatesFromSteps(steps, dates);

  return (
    <>
      <Card
        title={<FormattedMessage id="dates.title" />}
        aria-label="Dates"
        data-test-id={"move-dates-card"}
        extraTitleContent={
          <Typography
            variant="sBody"
            component="a"
            onClick={() => setOpenModal(true)}
            aria-label={formatMessage({ id: "ariaLabels.requestDatesChange" })}
            data-testid="request-dates-change"
          >
            <FormattedMessage id="actions.requestChange" />
          </Typography>
        }
      >
        <DatesTable>
          <thead>
            <tr>
              <th></th>
              <th>
                <Typography variant="xxsHeading">
                  <FormattedMessage id="dates.preferredDate" />
                </Typography>
              </th>
              <th>
                <Typography variant="xxsHeading">
                  <FormattedMessage id="dates.scheduledDate" />
                </Typography>
              </th>
              <th>
                <Typography variant="xxsHeading">
                  <FormattedMessage id="dates.completedDate" />
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {datesWithScheduled.map((date) => (
              <DateRow date={date} key={date.label} />
            ))}
          </tbody>
        </DatesTable>
      </Card>
      <DateChangeDialog
        openModal={openModal}
        setOpenModal={setOpenModal}
        moveStageDates={dates}
        orderId={orderId}
      />
    </>
  );
}
